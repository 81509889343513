@import 'styles/variables';

.container {
  align-items: center;
  background-color: $color-surface-lighter;
  display: flex;
  justify-content: space-between;
  line-height: 17px;
  padding: 20px $spacing;
  margin-top: auto;

  span {
    font-size: $font-size-11;
    text-align: center;
    flex-grow: 1;
  }
}

.arrow {
  width: 6.5px;
  padding: 0;
}

.button {
  font-size: $font-size-11;
  font-weight: $font-weight-semibold;
  min-height: unset;
  min-width: unset;
  padding: 0 10px;
  text-transform: uppercase;
  visibility: hidden;

  &.previous {
    visibility: visible;

    .arrow {
      margin-right: $spacing;
      transform: rotate(180deg);
    }
  }

  &.next {
    visibility: visible;

    .arrow {
      margin-left: $spacing;
      order: 1;
    }
  }
}
