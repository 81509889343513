@import 'styles/variables';

.buttonLabel {
  padding-top: 2px;
  display: block;
}

.dialogContent {
  background-color: $color-red-lightest;
  border: 1px solid $color-red;
  border-radius: $border-radius;
  color: $color-red;
  padding: $spacing-2x;
  list-style-type: disc;

  li {
    margin-left: $spacing-2x;
  }

  .completeMessage {
    padding-bottom: $spacing-quarter;
  }
}
