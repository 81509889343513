@import 'styles/variables';

$triangle-width: 10px;

.dialog {
  z-index: $zIndex5;
}

.dialogOverlay {
  background: transparent;
}

.dialogContentInner {
  background-color: rgba($color-white, 0.9);
  color: $color-text;
  font-size: $font-size-14;
  line-height: $line-height-atom;
  width: 315px;
  padding: $spacing-2x;
  text-align: left;
  transition: all 0.2s ease-in-out;

  &::after {
    // Arrow up
    border-bottom: $triangle-width solid rgba($color-white, 0.9);
    border-left: $triangle-width solid transparent;
    border-right: $triangle-width solid transparent;
    content: '';
    height: 0;
    left: calc(50% - 5px);
    position: absolute;
    top: -$triangle-width;
    width: 0;
  }

  .stepDots {
    display: flex;
    justify-content: center;
    margin-top: $spacing-3x;
  }

  .stepDot {
    padding: $spacing-half;
    background-color: white;
    border: 1px solid $color-green;
    border-radius: 50%;
    width: $spacing-half;
    height: $spacing-half;
    margin: 0 5px;

    &.active {
      background-color: $color-green;
      //border: 1px solid $color-white;
      pointer-events: none;
    }
  }

  .skipButton {
    position: absolute;
    top: $spacing-3x;
    right: $spacing-3x;
    color: $color-gray-darkest;
    font-size: $font-size-14;

    &:hover {
      text-decoration: underline;
    }
  }

  .pixel {
    width: 1px;
    height: 1px;
    position: fixed;
    top: -1px;
  }
}
