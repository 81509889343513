@import 'styles/variables';

.container {
  background-color: $color-surface-lighter;
  border-radius: $spacing-half;
  color: $color-gray-darkest;
  display: inline-block;
  font-weight: $font-weight-semibold;
  line-height: 18px;
  padding: 3px 5px;

  &.gray-darker {
    background-color: $color-gray-darker;
    color: $color-white;
  }

  &.gray-lighter {
    background-color: $color-gray-lighter;
    color: $color-gray-darker;
  }

  &.gray-lightest {
    background-color: $color-gray-lightest;
  }

  &.yellow-dark {
    background-color: $color-yellow-dark;
    color: $color-white;
  }

  &.green {
    background-color: $color-green;
    color: $color-white;
  }

  &.green-dark {
    background-color: $color-green-dark;
    color: $color-white;
  }

  &.green-dark-border {
    background-color: $color-green-dark;
    border: 1px solid $color-green;
    color: $color-white;
  }

  &.blue {
    background-color: $color-blue;
    color: $color-white;
  }

  &.red {
    background-color: $color-red;
    color: $color-white;
  }

  &.red-dark {
    background-color: $color-red-dark;
    color: $color-white;
  }
}

.chipSizeSmall {
  border-radius: 2px;
  font-size: $font-size-12;
  line-height: 10px;
  margin-left: $spacing;
  min-width: 16px;
  padding: $spacing-half;
  text-align: center;
}
