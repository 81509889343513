@import 'styles/variables';

.container {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: $zIndex5;
}
