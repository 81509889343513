@import 'styles/variables';

.checkbox {
  margin-top: $spacing;
}

input[type='checkbox'] + label .label {
  color: $color-text;
  font-size: $font-size-12;
}
