@import 'styles/variables';

.editButton {
  margin-left: $spacing;

  svg {
    fill: $color-blue;
    height: 15px;
    width: 15px;
  }
}

.banner {
  height: auto;
  padding: $spacing;
}

.summary {
  padding: $spacing-2x;
}

.message {
  font-size: $font-size-14;
  padding-bottom: 40px;
  text-align: center;
}

.link {
  align-items: center;
  background-color: $color-blue;
  border-radius: $border-radius;
  color: $color-white;
  display: flex;
  height: 40px;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  gap: $spacing;
  justify-content: center;
  line-height: 16px;
  width: 100%;

  &:hover {
    background-color: $color-blue-darker;
    color: $color-white;
  }

  .externalIcon {
    fill: $color-white;
    height: 16px;
    width: 16px;
  }
}
