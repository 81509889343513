@import 'styles/variables';

.carfaxLogo {
  height: 24px;
  width: 90px;
}

.label {
  display: flex;
  align-items: center;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  justify-content: space-between;
  padding: $spacing-2x 0 $spacing-half;

  &.red {
    color: $color-red-dark;
  }

  .warningGlyph {
    height: 18px;
    width: 18px;
  }
}

.lineItem {
  align-items: center;
  border-bottom: 1px solid $color-gray-lighter;
  display: flex;
  font-size: $font-size-12;
  justify-content: space-between;
  padding: 12px 0;

  .detail {
    font-weight: $font-weight-semibold;
  }

  .jurisdiction {
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    padding: $spacing 0;
  }

  .disclosure {
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    padding: $spacing-2x 0;
  }
}

.link {
  align-items: center;
  color: $color-blue;
  display: flex;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  gap: $spacing;
  padding: 12px 0;
  width: 100%;

  &:hover {
    color: $color-blue-darker;
  }

  .externalIcon {
    fill: $color-blue;
    height: 12px;
    width: 12px;
  }
}
