@import 'styles/variables';

.container {
  box-shadow: 0 4px 3px -3px rgba($color-black, 0.15);
  flex-shrink: 0;
  min-width: 1200px;
  padding: 0;
  position: relative;
  width: 100%;
  z-index: $zIndexNavigationContainer;
}

.firstLine {
  height: var(--system-navigation-height-single);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 $container-padding;
  background-color: $color-top-nav;

  &.actingAsUser {
    background-color: $color-red;
  }
}

.logo {
  margin-right: 1rem;
}

.menu {
  display: flex;
  margin-left: auto;
}

.userLink {
  &:hover {
    opacity: 0.85;
  }
}

.icon {
  width: 24px;
  height: 24px;
  margin-left: 15px;

  svg {
    width: 15px;
    height: 15px;
  }
}
