@import 'styles/variables';

.button {
  align-items: center;
  background-color: $color-gray-lightest;
  border-radius: 50%;
  color: $color-gray-lightest;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-left: 15px;
  position: relative;
  width: 24px;

  &:hover {
    opacity: 0.85;
  }
}

.sprite {
  stroke: $color-gray-darkest;
  width: 14px;
  height: 14px;
}
