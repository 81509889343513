@import 'styles/variables';
@import 'styles/typography';

.listItem {
  border-bottom: 1px solid $color-gray-lightest;
  margin-bottom: 0;
}

.listItemLink {
  padding-top: $spacing;
}

.body {
  display: grid;
  grid-template-columns: 75% auto;
}

.details {
  padding-bottom: $spacing;
}

.auctionTitle,
.pageTitle {
  color: $color-gray-dark;
  font-size: $font-size-14;
}

.vehicleTitle {
  color: $color-gray-darkest;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
}

.vehicleSubTitle {
  color: $color-gray-darkest;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;

  @extend %ellipsis;
}

.vin {
  color: $color-gray-darkest;
  font-size: $font-size-14;
}

.auctionTime {
  color: $color-gray-darkest;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
}

.badges {
  --badge-size: 24px;
  display: flex;
  flex-direction: row-reverse;
  gap: 3px;
  margin-top: $spacing;
}
