@import 'styles/variables';
@import './conditionReport';

.carfax {
  width: 83px;
}

.carfaxUSButton {
  margin-bottom: -$spacing;
}

.mmgCarfax {
  align-items: center;
  background-color: $color-white;
  border: 1px solid $color-gray-light;
  border-radius: $border-radius-small;
  column-gap: $spacing-half;
  display: flex;
  flex-flow: row;
  height: auto;
  justify-content: space-between;
  padding: 7px;
  width: initial;

  &.red {
    background-color: $color-red-lightest;
    border-color: $color-red;
  }
}

.mmgAmount {
  color: $color-gray-darker;
  font-size: $font-size-12;
  font-weight: $font-weight-bold;
}

.mmgCarfaxImage {
  height: 16px;
  width: auto;
}

.buttonContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.claimsFound {
  color: $color-red-dark;
}
