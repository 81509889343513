@import 'styles/variables';

$base-padding: 12px;

.inventory {
  border-top: solid 1px rgba($color-line, 0.3);
  font-size: 0.75rem;
}

.sellerNotes {
  line-height: $line-height-medium;
  margin: -4px 0;
  width: 100%;

  .editButton {
    top: -15px;
  }
}

.sellerNotesContent {
  white-space: pre-line;
  word-break: break-word;
}

.editButton {
  position: absolute;
  top: 10px;
  right: 10px;

  svg {
    width: 12px;
    height: 12px;
    fill: $color-blue;
  }
}

.declarations,
.options {
  display: flex;
  flex-flow: row wrap;
  gap: $spacing;
  font-size: $font-size-12;
}

.declaration {
  background-color: $color-gray-lightest;
  border: 2.5px solid $color-gray-darkest;
  color: $color-gray-darkest;

  &.red {
    background-color: $color-red-lightest;
    border-color: $color-red;
  }

  &.green {
    background-color: $color-green-lightest;
    border-color: $color-green;
  }
}

.lastKnownMileageRow {
  margin: 10px 0 0;
}
